import React from 'react';

import Layout from '@layouts/index';
import Seo from '@layouts/common/seo';
import FaqsHero from '@components/faqs/faqs-hero';
import FaqsAccordions from '@components/faqs/faqs-accordion';
import FaqsContact from '@components/faqs/faqs-contact';

const FaqsPage = () => {
  return (
    <Layout>
      <Seo title="Facts and Knowledge" />
      <FaqsHero />
      <FaqsAccordions />
      <FaqsContact />
    </Layout>
  );
};

export default FaqsPage;
