import React from 'react';
import { Link } from 'gatsby';

import LeftArrowContact from '@assets/contact/left-arrow';

const FaqsHero = () => {
  return (
    <div className="faqs-hero-outermost u-margin-top-lg">
      <div className="faqs-hero-container common-container">
        <div className="faqs-hero-breadcrump">
          <ul className="privacy-policy-breadcrumb">
            <Link to="/">
              <li className="opacity-low">Home</li>
            </Link>
            <LeftArrowContact />
            <li>Faqs</li>
          </ul>
        </div>
        <div className="faqs-hero-title">
          <h1>Frequently asked</h1> <h1 className="h1-modified">questions</h1>
          <p className="faqs-hero-desc body1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida cras imperdiet rutrum vel dolor, cursus et
            tortor sit. Sit felis tellus leo purus aliquet sapien neque. Nec volutpat nec eu scelerisque blandit
            imperdiet.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FaqsHero;
