import React from 'react';
import { Link } from 'gatsby';

import BlogButton from '@components/reusable-buttons/blog-button';

const FaqsContact = () => {
  return (
    <div className="faqs-contact-container common-container">
      <h2>Need answers</h2> <h2 className="h2-modified">to your query?</h2>
      <p className="faqs-contact-desc body1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida cras imperdiet rutrum vel dolor, cursus et
        tortor sit. Sit felis tellus leo purus aliquet sapien neque. Nec volutpat nec eu scelerisque blandit imperdiet.{' '}
      </p>
      <div className="faqs-contact-button">
        <Link to="/contact">
          <BlogButton buttonText="Contact Us" />
        </Link>
      </div>
    </div>
  );
};
export default FaqsContact;
