import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import Arrow from '@assets/svg/arrow';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(44.8),
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.body1,
  },
  description: {
    fontSize: theme.typography.pxToRem(26.6),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: theme.typography.body1,
  },
}));

export default function FaqsAccordion() {
  const classes = useStyles();

  return (
    <div className="faqs-accordion-holder">
      <h6 className="accordion-heading">Top Questions</h6>
      <div className={classes.root}>
        <Accordion className="accordion-item">
          <AccordionSummary expandIcon={<Arrow />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>
              <h5>Frequently asked questions</h5>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              <div className="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida cras imperdiet rutrum vel dolor, cursus
                et tortor sit. Sit felis tellus leo purus aliquet sapien neque. Nec volutpat nec eu scelerisque blandit
                imperdiet. Risus ultricies sit amet, dictum. Pharetra mollis donec cursus morbi nunc. Nunc, ullamcorper
                mi amet, habitant hac vitae. Lacus eget arcu, ut interdum senectus nulla metus. Etiam ridiculus viverra
                etiam vitae massa. Vitae molestie pellentesque malesuada fermentum, risus in cum viverra. Adipiscing
                adipiscing et leo dictumst. Eget massa aliquam magna tempor, sed neque sit platea. Nulla quam neque, sem
                urna eget aliquam nisi sed consectetur. Et, sed sed ultricies sit ut. Nunc.
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="accordion-item">
          <AccordionSummary expandIcon={<Arrow />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography className={classes.heading}>
              <h5>Frequently asked questions 2</h5>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              <div className="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida cras imperdiet rutrum vel dolor, cursus
                et tortor sit. Sit felis tellus leo purus aliquet sapien neque. Nec volutpat nec eu scelerisque blandit
                imperdiet. Risus ultricies sit amet, dictum. Pharetra mollis donec cursus morbi nunc. Nunc, ullamcorper
                mi amet, habitant hac vitae. Lacus eget arcu, ut interdum senectus nulla metus. Etiam ridiculus viverra
                etiam vitae massa. Vitae molestie pellentesque malesuada fermentum, risus in cum viverra. Adipiscing
                adipiscing et leo dictumst. Eget massa aliquam magna tempor, sed neque sit platea. Nulla quam neque, sem
                urna eget aliquam nisi sed consectetur. Et, sed sed ultricies sit ut. Nunc.
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="accordion-item">
          <AccordionSummary expandIcon={<Arrow />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography className={classes.heading}>
              <h5>Frequently asked questions 3</h5>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              <div className="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida cras imperdiet rutrum vel dolor, cursus
                et tortor sit. Sit felis tellus leo purus aliquet sapien neque. Nec volutpat nec eu scelerisque blandit
                imperdiet. Risus ultricies sit amet, dictum. Pharetra mollis donec cursus morbi nunc. Nunc, ullamcorper
                mi amet, habitant hac vitae. Lacus eget arcu, ut interdum senectus nulla metus. Etiam ridiculus viverra
                etiam vitae massa. Vitae molestie pellentesque malesuada fermentum, risus in cum viverra. Adipiscing
                adipiscing et leo dictumst. Eget massa aliquam magna tempor, sed neque sit platea. Nulla quam neque, sem
                urna eget aliquam nisi sed consectetur. Et, sed sed ultricies sit ut. Nunc.
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="accordion-item">
          <AccordionSummary expandIcon={<Arrow />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography className={classes.heading}>
              <h5>Frequently asked questions 4</h5>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              <div className="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida cras imperdiet rutrum vel dolor, cursus
                et tortor sit. Sit felis tellus leo purus aliquet sapien neque. Nec volutpat nec eu scelerisque blandit
                imperdiet. Risus ultricies sit amet, dictum. Pharetra mollis donec cursus morbi nunc. Nunc, ullamcorper
                mi amet, habitant hac vitae. Lacus eget arcu, ut interdum senectus nulla metus. Etiam ridiculus viverra
                etiam vitae massa. Vitae molestie pellentesque malesuada fermentum, risus in cum viverra. Adipiscing
                adipiscing et leo dictumst. Eget massa aliquam magna tempor, sed neque sit platea. Nulla quam neque, sem
                urna eget aliquam nisi sed consectetur. Et, sed sed ultricies sit ut. Nunc.
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="accordion-item">
          <AccordionSummary expandIcon={<Arrow />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography className={classes.heading}>
              <h5>Frequently asked questions 5</h5>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              <div className="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida cras imperdiet rutrum vel dolor, cursus
                et tortor sit. Sit felis tellus leo purus aliquet sapien neque. Nec volutpat nec eu scelerisque blandit
                imperdiet. Risus ultricies sit amet, dictum. Pharetra mollis donec cursus morbi nunc. Nunc, ullamcorper
                mi amet, habitant hac vitae. Lacus eget arcu, ut interdum senectus nulla metus. Etiam ridiculus viverra
                etiam vitae massa. Vitae molestie pellentesque malesuada fermentum, risus in cum viverra. Adipiscing
                adipiscing et leo dictumst. Eget massa aliquam magna tempor, sed neque sit platea. Nulla quam neque, sem
                urna eget aliquam nisi sed consectetur. Et, sed sed ultricies sit ut. Nunc.
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
